
import { Roles } from "@/api";
import { Permission } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ValidationProvider, ValidationObserver, PageTitle }
})
export default class CreateUserRolesPage extends Vue {
  headersTitle = [{ icon: "mdi-account-lock", header: "Create Role" }];
  buttonsArray = [
    {
      text: "Back to list",
      icon: "",
      color: "gray",
      action: "back"
    }
  ];
  name = "";
  permissions: Array<Permission> = [];
  selectedPermissions: Array<number> = [];

  async created() {
    this.permissions = await Roles.permissions();
  }
  async save() {
    try {
      if (!this.selectedPermissions.length) {
        await this.$error({ errors: "Select permissions" });
        return;
      }
      await Roles.create(this.name, this.selectedPermissions);
      await this.$success("<strong>Success!</strong> Role has been created!");
      await this.$router.push({ name: "UserRoles" });
    } catch (e) {
      (this.$refs.userForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }
}
